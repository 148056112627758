// extracted by mini-css-extract-plugin
export var arrow = "workpage-module--arrow--CTPRa";
export var borderimage = "workpage-module--borderimage--vygg4";
export var divider = "workpage-module--divider--r2QRP";
export var halfimage = "workpage-module--halfimage--Wx9Fa";
export var image = "workpage-module--image--EzSSM";
export var imagecontainer = "workpage-module--imagecontainer--XeTzO";
export var imageflex = "workpage-module--imageflex--9WRxH";
export var infoContainer = "workpage-module--infoContainer--9TdN3";
export var intro = "workpage-module--intro--Ey4ek";
export var introContainer = "workpage-module--introContainer--1ECD6";
export var leftContainer = "workpage-module--leftContainer--Hu-Hw";
export var link = "workpage-module--link--o8heO";
export var paywallmessage = "workpage-module--paywallmessage--dVBi5";
export var projectSection = "workpage-module--projectSection--5riDk";
export var projectText = "workpage-module--projectText--s+3OO";
export var rightContainer = "workpage-module--rightContainer--irNUn";
export var spacer = "workpage-module--spacer--AFUxI";
export var strikethrough = "workpage-module--strikethrough--t2Map";
export var subTitle = "workpage-module--subTitle--wNJWp";
export var text = "workpage-module--text--U3RYu";
export var title = "workpage-module--title--BeMQn";